<template>
	<div class="pui-form">
		<v-form @submit.prevent class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation v-if="modelLoaded">
			<div row wrap class="pui-form-layout">
				<pui-field-set :title="$t('pmunit.title')">
					<v-layout wrap>
						<v-flex xs12 md4>
							<pui-text-field :label="$t('pmunit.name')" v-model="model.name" required toplabel maxlength="150"></pui-text-field>
						</v-flex>
						<v-flex xs12 md4>
							<pui-text-field v-model="model.symbol" :label="$t('pmunit.symbol')" maxlength="10" toplabel></pui-text-field>
						</v-flex>
						<v-flex xs12 md4>
							<pui-select
								attach="pmdatatypeid"
								:label="this.$t('pmunit.datatype')"
								:placeholder="this.$t('pmunit.phdatatype')"
								toplabel
								clearable
								required
								:disabled="formDisabled"
								v-model="model"
								modelName="vluppmdatatype"
								:modelFormMapping="{ pmdatatypeid: 'pmdatatypeid' }"
								:itemsToSelect="datatypeItemsToSelect"
								itemValue="pmdatatypeid"
								itemText="type"
							></pui-select>
						</v-flex>
					</v-layout>
					<v-layout wrap>
						<v-flex xs12>
							<pui-text-area v-model="model.description" :label="$t('pmunit.description')" maxlength="250" toplabel></pui-text-area>
						</v-flex>
					</v-layout>
					<v-layout wrap>
						<v-flex xs12 md4>
							<pui-checkbox
								:label="$t('pmunit.disabled')"
								v-model="model.disabled"
								true-value="1"
								false-value="0"
								style="margin-top: -1em !important"
							></pui-checkbox>
						</v-flex>
					</v-layout>
				</pui-field-set>
			</div>
			<!-- footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns
					:formDisabled="formDisabled"
					:saveDisabled="saving"
					:saveAndNew="saveAndNew"
					:saveAndUpdate="saveAndUpdate"
					:save="save"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
export default {
	name: 'pmunitform',
	mixins: [PuiFormMethodsMixin],

	data() {
		return {
			modelName: 'pmunit',
			model: 'pmunit'
		};
	},
	computed: {
		datatypeItemsToSelect() {
			return [{ pmdatatypeid: this.model.pmdatatypeid }];
		}
	},
	beforeCreate() {
		if (this.model) {
			this.modelLoaded = true;
		}
	},
	methods: {}
};
</script>
